import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Line, Svg, Image } from '@react-pdf/renderer';
import moment from 'moment';
import { formatMoney } from '../utils/formatMoney';

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 10
  },
  section: {

    fontSize: '8pt'
  }
});

// Create Document Component
export const SessionInvoice: React.FC<{ session: any, patient: any }> = ({ session, patient }) => (
  <PDFViewer width='100%' height="100%">
    <Document >
      <Page size={[147.4, 280]} style={styles.page} orientation='portrait' >
        <View style={{ ...styles.section, marginBottom: 10 }}>
          <Text style={{ textAlign: 'center', fontSize: '12pt' }}>Denticare</Text>
          <Text style={{ textAlign: 'center', fontSize: '10pt' }}>Comprobante de Cita</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <Text style={{ textAlign: 'center' }}>#Ref:</Text>
          <Text style={{ textAlign: 'center' }}>{session.id}</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
          <Text style={{ textAlign: 'center' }}>Fecha:</Text>
          <Text style={{ textAlign: 'center' }}>{moment(session.date, 'YYYY-MM-DD').format("DD/MM/YY")}</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text>Paciente:</Text>
          <Text>{patient.first_name} {patient.last_name}</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text style={{ textAlign: 'center' }}>Doctor:</Text>
          <Text style={{ textAlign: 'center' }}>{session.doctor}</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'center', marginTop: 5 }}>
          <Text style={{ textAlign: 'center' }}>Detalle:</Text>
        </View>
        {
          session.billables.map((billable: any) => (
            <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', fontSize: '8pt' }}>
              <Text>{billable.quantity}x {billable.name}</Text>
              <Text>  {formatMoney(billable.quantity * billable.price, { currency: "Bs. " })}</Text>
            </View>
          ))
        }

        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', fontSize: '9pt', marginTop: 10 }}>
          <Text>Total: </Text>
          <Text style={{ textAlign: 'center', fontWeight: 500 }}> {formatMoney(session.billables.reduce((acc: any, billable: any) => acc + billable.quantity * billable.price, 0))}</Text>
        </View>
        <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', fontSize: '9pt', marginTop: 15 }}>
          <Text>Firma: _____________________  </Text>
        </View>
        <View style={{ alignItems: 'center', justifyContent: 'center' }} >
          <Image style={{ width: 80, height: 80 }} src={`/api/sessions/${session.id}/qr_code.png`} />
        </View>

      </Page>
    </Document>
  </PDFViewer >
);

export const PaymentInvoice: React.FC<{ patient: any, data: any }> = ({ patient, data }) => {
  return (
    <PDFViewer width='100%' height="100%">
      <Document >
        <Page size='A8' style={styles.page} orientation='portrait' >
          <View style={{ ...styles.section, marginBottom: 10 }}>
            <Text style={{ textAlign: 'center', fontSize: '12pt' }}>Denticare</Text>
            <Text style={{ textAlign: 'center', fontSize: '10pt' }}>Comprobante de Pago</Text>
          </View>
          <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
            <Text style={{ textAlign: 'center' }}>#Ref:</Text>
            <Text style={{ textAlign: 'center' }}>{data.id}</Text>
          </View>
          <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', margin: 0 }}>
            <Text style={{ textAlign: 'center' }}>Fecha:</Text>
            <Text style={{ textAlign: 'center' }}>{moment(data.created_at).format("DD/MM/YY")}</Text>
          </View>
          <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text>Paciente:</Text>
            <Text>{patient.name}</Text>
          </View>
          <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text>Metodo de Pago:</Text>
            <Text>{data.payment_method}</Text>
          </View>
          <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ textAlign: 'center' }}>Monto:</Text>
            <Text style={{ textAlign: 'center' }}>{formatMoney(data.amount)}</Text>
          </View>

          <View style={{ ...styles.section, flexDirection: 'row', justifyContent: 'space-between', fontSize: '9pt', marginTop: 15 }}>
            <Text>Firma: _____________________  </Text>

          </View>


        </Page>
      </Document>
    </PDFViewer >
  )
}